let unmuteBtn = document.getElementById('unmute-btn')
let muteState = sessionStorage.getItem('videos_muted')
let player = null

function getMuteStatus(player) {
    if (muteState === null ) {
        let muted = player.muted

        if (muted) {
            showPlaySoundBtn(true)
        }

        return muted
    } else if (muteState === 'true') {
        showPlaySoundBtn(true)
        return true
    } else {
        player.muted = false
        player.removeAttribute('muted')
        return false
    }
}

function showPlaySoundBtn(condition) {
    if (unmuteBtn) {
        condition ? unmuteBtn.style.display = 'flex' : unmuteBtn.style.display = 'none'
    }
}

function onUnmuteClick() {
    player.muted = false
    player.removeAttribute('muted')
}

function onVolumeChange() {
    sessionStorage.setItem('videos_muted', player.muted)
    showPlaySoundBtn(player.muted)
}

function initUnmute(playerObj) {
    player = playerObj

    player.addEventListener('volumechange', onVolumeChange)

    if (unmuteBtn) {
        unmuteBtn.addEventListener('click', onUnmuteClick)
    }
}

export {
    getMuteStatus,
    initUnmute
}