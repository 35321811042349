// Issue described in PFL-221
// A small hack needed because WAVE accessibility validator (https://wave.webaim.org/) doesn't ignore hidden elements
function hackVideoJSforWAVE() {
    const hiddenMenuList = document.querySelectorAll('.vjs-hidden .vjs-menu [role="menu"]')

    hiddenMenuList.forEach(element => {
        element.removeAttribute('role')
    })
}

export {
    hackVideoJSforWAVE
}