import 'video.js/dist/video-js.css'
import videojs from 'video.js'
import {VR} from 'videojs-vr/dist/videojs-vr'
import { initSiblingsSlider } from '../siblings-slider'
import { initSimpleSlider } from '../simple-slider'

import { getMuteStatus, initUnmute } from './unmute'
import { initOverlay } from './overlay'
import { initHelpers, isChapterWatched } from './helpers'
import { hackVideoJSforWAVE } from './accessibility'

let player = document.querySelector('.video-js')
let userAgent = navigator.userAgent || navigator.vendor || window.opera
const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
const vrNotificationEl = document.querySelector('.vr-notification')

function initVideo(isVideoVr) {
    if (player) {
        let videoPlayer = videojs(player)

        if (isVideoVr) {
            if (isIOS) {
                vrNotificationEl.style.display = 'block'
            }
            videoPlayer.vr({projection: '360'})
        }
        
        videoPlayer.ready(function() {
            initOverlay(player, this)
            player.muted = getMuteStatus(player)
            setTimeout(hackVideoJSforWAVE, 1000)
        })

        player.addEventListener('play', () => {
            // Fix for intemittent Firefox play button display
            if (player.parentNode.classList.contains('vjs-paused')) {
                player.parentNode.classList.remove('vjs-paused')
                player.parentNode.classList.add('vjs-has-started')

                // Fix was removed for SB-1272 (Skip to content button) to work
                // player.focus()
            }
        })
        
        initUnmute(player)
        initHelpers(player)
        isChapterWatched()
    }
}

initSiblingsSlider()
initSimpleSlider()

export {
    initVideo
}
