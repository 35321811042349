import { cancelAutoplay, replayVideo } from './overlay'
import { openShareList } from '../accessibility/chapter'
import { createPlayList } from '../playlist/playlist.js'
import { addVideoToPlaylist, removeVideoFromPlaylist, checkPlaylistButtons } from '../playlist/helpers.js'

function toggleTranscript() {
    const transcriptNode = document.getElementById('video-transcript')
    const descriptionNode = document.getElementById('video-description')

    // Check if transcript node is visible
    if (!!( transcriptNode.offsetWidth || transcriptNode.offsetHeight || transcriptNode.getClientRects().length )) {
        transcriptNode.style.display = 'none' // Hide the transcript node
        descriptionNode.style.display = 'block' // Show the description node
    } else {
        transcriptNode.style.display = 'block' // Show the transcript node
        descriptionNode.style.display = 'none' // Hide the description node
    }
    $(".video-player .description .action span").toggleClass('in');
}

function toggleTranscriptLanguage(transcriptId) {
    const flags = document.querySelectorAll(`.language-label`)

    for (const flag of flags) {
        if (flag.dataset.id === transcriptId) {
            flag.classList.toggle('selected')
        } else {
            flag.classList.remove('selected')
        }
    }
}

function initPlaylistButtons(guid) {
    let currentVideo = document.querySelector('.video-player')
    let store = JSON.parse(localStorage.getItem('playlist')) || []
    if (currentVideo) {
        store.forEach(item => { 
            if (item.guid == guid) {
                checkPlaylistButtons(guid, true)
            } 
        })
    }
}

function isChapterWatched() {
    let chapterBlock = document.querySelector('[data-watched-chapter]')
    let storedVisits = localStorage.getItem('pageVisits')
    let visitedPages = JSON.parse(storedVisits) || []
    
    if (chapterBlock) {
        let guid = chapterBlock.getAttribute('data-guid')
        if ( visitedPages.includes(guid) ) {
            chapterBlock.setAttribute('data-watched-chapter', 'true')
        }
        trackPage(guid)
    }
}

function trackPage(page) {
    let storedVisits = localStorage.getItem('pageVisits')
    let visitedPages = JSON.parse(storedVisits) || []
    
    if (!visitedPages.includes(page)) {
        let isSafariIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)
        let isIpadPro = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
        let eventName = isSafariIOS ? "pagehide" : "beforeunload"
        if (isIpadPro) {
            setTimeout( function() {
                addPageToVisided(page, visitedPages)
            }, 5000)
        } else {
            window.addEventListener(eventName, function (event) {
                addPageToVisided(page, visitedPages)
            })
        }
    }
}

function addPageToVisided(page, list) {
    list.push(page)
    localStorage.setItem('pageVisits', JSON.stringify(list))
}

function initHelpers() {
    // Helpers setup
    window.helpers.initPlaylistButtons = initPlaylistButtons
    window.helpers.removeVideoFromPlaylist = removeVideoFromPlaylist
    window.helpers.addVideoToPlaylist = addVideoToPlaylist
    window.helpers.openShareList = openShareList
    window.helpers.toggleTranscript = toggleTranscript
    window.helpers.toggleTranscriptLanguage = toggleTranscriptLanguage
    window.helpers.cancelAutoplay = cancelAutoplay
    window.helpers.replayVideo = replayVideo
}

export {
    initHelpers,
    isChapterWatched
}