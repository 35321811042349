let player = null

let timerValue = 10
let timerId = undefined
let escFlag = false

const overlay = document.querySelector('.video-overlay')
const timerValueNode = document.querySelector('.video-next-timer-value')
const nextUrl = document.querySelector('.video-next-title')


function cancelAutoplay() {
    clearInterval(timerId)
    overlay.classList.remove("visible")
}

function replayVideo() {
    cancelAutoplay()
    // Rewind video
    player.currentTime = 0
    player.play()
    player.focus()

    return false
}

function hideOverlayOnEsc(event) {
    if (escFlag) {
        cancelAutoplay()
    } 
    escFlag = false
}

function showOverlay() {
    overlay.classList.add("visible")
    overlay.addEventListener('blur', hideOverlayOnEsc)

    // Hide video spinner
    if (document.querySelector('.video-js').parentNode.classList.contains('vjs-waiting')) {
        document.querySelector('.video-js').parentNode.classList.remove('vjs-waiting')
    }

    timerValue = +player.dataset.autoplayCountdown
    timerValueNode.textContent = timerValue

    timerId = setInterval(() => {
        timerValue -= 1
        timerValueNode.textContent = timerValue

        if (timerValue === 0) {
            clearInterval(timerId)
            location.replace(nextUrl.href)
        }
    }, 1000)
}

function hideOverlay() {
    cancelAutoplay()
    overlay.removeEventListener('blur', hideOverlayOnEsc)
}

// Track ESC press on document
function initAccessibility() {
    document.addEventListener("keydown", event => {
        if (event.key == "Escape") {
            escFlag = true
            hideOverlayOnEsc(event)
        }
    })
}

function playNextPlaylistVideo() {
    let videoIndex = parseInt(localStorage.getItem('playlistIndex')) + 1
    let store = JSON.parse(localStorage.getItem('playlist')) || []
    if ( videoIndex <= store.length - 1 ) {
        localStorage.setItem('playlistIndex', videoIndex)
        location.href = store[videoIndex].pageUrl
    } else {
        localStorage.setItem('playlistIndex', 'false')
    }
}

function initOverlay(playerNode, playerInstance) {
    let isPlaylistStarted = localStorage.getItem('playlistIndex')
    player = playerNode

    if (overlay) {
        initAccessibility()
        
        // Show player overlay only if enabled in the settings
        if (player.dataset.autoswitch && player.dataset.autoswitch == 'True') {
            // Check if video is from playlist
            if (!isPlaylistStarted || isPlaylistStarted == null) {
                playerInstance.on('ended', showOverlay)
            } else {
                playerInstance.on('ended', playNextPlaylistVideo)
            }
        }

        // Moving overlay inside the video.js container to work in fullscreen too
        document.querySelector('.video-js').insertBefore(overlay, null)
    }
}

export {
    initOverlay,
    cancelAutoplay,
    replayVideo
}