import { initSlider } from './page-slider'

function initSiblingsSlider () {
	let sliderContainer = document.querySelector('.siblings-horizontal')

	if (sliderContainer) {
  	initSlider(sliderContainer, "channel")
	}
}

export {
  initSiblingsSlider
}