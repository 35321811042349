function initSimpleSlider() {
  const sliderContainer = document.querySelector('.simple-slider')
  let slides
  let slidesNumber
  let visibleSlidesWidth
  let arrowLeft
  let arrowRight
  let offset = parseFloat(localStorage.getItem('offset'))
  let slideWidth
  let slidesWidth
  let slidesPerView

  if (sliderContainer) {
    let slide
    let style
    slides = document.querySelector('.slides')
    slidesNumber = parseInt(slides.dataset.slideCount)
    visibleSlidesWidth = slides.offsetWidth
    arrowLeft = sliderContainer.querySelector('.arrow-left')
    arrowRight = sliderContainer.querySelector('.arrow-right')

    slide = slides.querySelector('.slider-block')
    style = slide.currentStyle || window.getComputedStyle(slide)

    slideWidth = slide.offsetWidth + parseInt(style.marginRight, 10)
    slidesWidth = slideWidth*(slidesNumber-1) + slide.offsetWidth
    slidesPerView = Math.floor(visibleSlidesWidth/slideWidth)

    // Add click listeners to slider controls
    arrowLeft.addEventListener('click', movePrev)
    arrowRight.addEventListener('click', moveNext)
    arrowLeft.disabled = true

    // Hide controls if not enough slides for scroll
    if (slidesWidth <= visibleSlidesWidth ) {
      arrowLeft.style.visibility = 'hidden'
      arrowRight.style.visibility = 'hidden'
    }

    checkForActiveSlide()
  }
  function moveToSlide(index, view) {
    if (index <= view) {
      if (index !== 1) {
        arrowLeft.disabled = false
      }
      if (index + slidesPerView > slidesNumber) {
        offset = slidesWidth-visibleSlidesWidth
        arrowRight.disabled = true
      } else {
        offset = (index-1)*slideWidth
      }
      slides.style.left = `-${offset}px`
    } else {
      view += slidesPerView
      moveToSlide(index, view)
    }
  }
  function checkForActiveSlide() {
    let currentPageGuid = document.querySelector('body').dataset.guid
    // Calibrate track so the active slide is visible
    if (currentPageGuid) {
      let currentSlide = slides.querySelector(`[data-guid="${currentPageGuid}"]`)
      let index = parseInt(currentSlide.getAttribute('data-index'))
      moveToSlide(index, slidesPerView)
    } else {
      moveToSlide(1, slidesPerView)
    }
  }
  function movePrev() {
    arrowRight.disabled = false
    if (offset < slidesWidth/slidesNumber){
      offset = 0
      arrowLeft.disabled = true
    } else {
      offset -= slidesWidth/slidesNumber
    }
    slides.style.left = `-${offset}px`
  }
  function moveNext() {
    arrowLeft.disabled = false
    // Check if next slide is on the last page
    if (slidesWidth-visibleSlidesWidth-offset <= slideWidth){
      offset = slidesWidth-visibleSlidesWidth
      arrowRight.disabled = true
    } else {
      offset += slidesWidth/slidesNumber
    }
    slides.style.left = `-${offset}px`
  }
}

export {
  initSimpleSlider
}
